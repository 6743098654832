/*Chrome, Safari and Opera*/
.paper1::-webkit-scrollbar,
.paper2::-webkit-scrollbar {
    display: none;
}

/* IE, Edge and Firefox */
.paper1,
.paper2 {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

