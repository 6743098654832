$mof-primary: #056310;
$mof-secondary: #6C757D;
$mof-leave: #007BFF;

.btn-mof-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #056310;
  --bs-btn-border-color: #056310;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #054f0b;
  --bs-btn-hover-border-color: #04490b;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #054f0b;
  --bs-btn-active-border-color: #044d0c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #056310;
  --bs-btn-disabled-border-color: #056310;
}

.btn-mof-outline-primary {
  --bs-btn-color: #056310;
  --bs-btn-border-color: #056310;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #056310;
  --bs-btn-hover-border-color: #056310;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #056310;
  --bs-btn-active-border-color: #056310;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #056310;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #056310;
  --bs-gradient: none;
}

.btn-mof-info {
  --bs-btn-color: #FFF;
  --bs-btn-bg: #17A2B8;
  --bs-btn-border-color: #17A2B8;
  --bs-btn-hover-color: #FFF;
  --bs-btn-hover-bg: #148ea1;
  --bs-btn-hover-border-color: #148ea1;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #FFF;
  --bs-btn-active-bg: #148ea1;
  --bs-btn-active-border-color: #148ea1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #17A2B8;
  --bs-btn-disabled-border-color: #17A2B8;
}

.btn-mof-info-secondary {
  --bs-btn-color: #17A2B8;
  --bs-btn-bg: #D1ECF1;
  --bs-btn-border-color: #D1ECF1;
  --bs-btn-hover-color: #17A2B8;
  --bs-btn-hover-bg: #c3e1e7;
  --bs-btn-hover-border-color: #c3e1e7;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #17A2B8;
  --bs-btn-active-bg: #c3e1e7;
  --bs-btn-active-border-color: #c3e1e7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #17A2B8;
  --bs-btn-disabled-border-color: #17A2B8;
}

.btn-mof-outline-info {
  --bs-btn-color: #17A2B8;
  --bs-btn-border-color: #17A2B8;
  --bs-btn-hover-color: #FFF;
  --bs-btn-hover-bg: #17A2B8;
  --bs-btn-hover-border-color: #17A2B8;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #FFF;
  --bs-btn-active-bg: #17A2B8;
  --bs-btn-active-border-color: #17A2B8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #17A2B8;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #17A2B8;
  --bs-gradient: none;
}

.bg-mof-primary {
  background-color: $mof-primary;
}

.text-mof-primary {
  color: $mof-primary;
}

.none-underline {
  text-decoration: none;
}

.mof-form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  width: 1.2em;
  height: 1.2em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 2px solid var(--bs-border-color);
  border-radius: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  print-color-adjust: exact;
}

.mof-form-check-input:focus {
  border-color: #2a9a37;
  outline: 0;
  box-shadow: #056310;
}

.mof-form-check-input:checked {
  background-color: $mof-primary;
  border-color: $mof-primary;
}

.mof-form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.mof-form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.mof-form-check-input[type=checkbox]:indeterminate {
  background-color: $mof-primary;
  border-color: $mof-primary;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.mof-form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

.text-leave {
  color: $mof-leave;
}

.border-leave {
  border-color: $mof-leave;
}