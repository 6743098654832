//$primary: #056310;
$info: #17A2B8;
$danger: #ED5245;
$success: #0EA552;

$accordion-bg: #F3F3F3;
$accordion-button-active-bg: #F3F3F3;
$accordion-border-color: #F3F3F3;

@import 'bootstrap/scss/bootstrap.scss';

@import 'react-notifications-component/dist/scss/notification.scss';

@import './fonts.scss';

@import 'react-pdf/dist/cjs/Page/AnnotationLayer.css';
@import 'react-pdf/dist/cjs/Page/TextLayer.css';

@import 'react-calendar/dist/Calendar.css';

@import './base-color.scss';

@import "react-toggle/style.css";

body {
  font-family: 'Anuphan', sans-serif;
}

.profile-logo {
  border: 2px solid #a8a8a8;
  border-radius: 100%;
  height: 55px;
  width: 55px;
  display: flex;

  svg {
    margin: auto;
    font-size: 38px;
    color: #a8a8a8;
  }
}

.employee-image-card {
  min-width: 120px;
}

.employee-photo {
  border-radius: 20%;
  max-width: 120px;
  height: 100%;
}

.sidebar {
  max-width: 230px;
}

.btn-action {
  width: 150px;
  height: 50px;
}

.btn-action-height {
  min-height: 50px;
}

.table {
  border: none;

  th:last-child {
    border-top-right-radius: .5rem;
    //border-bottom-right-radius: .5rem;
  }

  th:first-child {
    border-top-left-radius: .5rem;
    //border-bottom-left-radius: .5rem;
  }

  th {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    background-color: $mof-secondary;
    color: $white;
    border: none;
  }

  td {
    border: none;
  }
}

.bg-gray {
  background-color: #F3F3F3;
}

.table-column-action {
  width: 5%;
}

.pointer:hover {
  cursor: pointer;
}

.full-page-loader {
  position: fixed;
  z-index: 9999;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(243, 243, 243, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.required:after {
  content: ' *';
  color: red;
}

.disable {
  color: $input-disabled-color;
  background-color: $input-disabled-bg;
  border-color: $input-disabled-border-color;
}

.form-group {
  position: relative;
}

.form-group .form-control-icon {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  right: 0;
  top: 0;
}

.was-validated .form-date-control:invalid, .form-date-control.is-invalid {
  background-image: none;
}

.was-validated .form-date-control:valid, .form-date-control.is-valid {
  background-image: none;
}

.content-background-color {
  background-color: #F3F3F3;
}

.min-organizational-structure-width {
  min-width: 120px;
}

.organizational-structure-root {
  width: 200px;
}

.organizational-structure-shadow {
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.directors {
  background-color: #ED5245;
}

.deputy-directors {
  background-color: #519D00;
}

.department-offices {
  background-color: #4DB5FF;
}

.division-group-offices {
  background-color: #0077CB;
}

.structure-departments {
  background-color: #006075;
}

.structure-positions {
  background-color: #6C6C6C;
}

.custom-no-validation .form-check-input.no-validate + .form-check-label {
  color: black;
}

.bg-card {
  background-color: #D9D9D9;
}

.primary-select {
  &:hover {
    background-color: #2684FF;
    color: white;
  }
}

.primary-select-active {
  background-color: #2684FF;
  color: white;
}

.calendar-index {
  z-index: 999;
}

.overflow-x {
  overflow-x: scroll;
}

.min-150 {
  min-width: 150px;
}

.min-230 {
  min-width: 230px;
}

.form-control:valid:not([required]) {
  border-color: #ced4da; /* Use the desired border color for non-required fields */
  box-shadow: none; /* Remove any box shadow */
  background-image: none;
}

.form-control:focus:valid:not([required]) {
  border-color: #ced4da; /* Use the desired border color for non-required fields */
  box-shadow: none; /* Remove any box shadow */
}

.form-check-input:valid:not([required]) {
  border-color: #ced4da; /* Use the desired border color for non-required fields */
  box-shadow: none; /* Remove any box shadow */
}

.form-check-input:valid:not([required]) ~ .form-check-label {
  border-color: #ced4da; /* Use the desired border color for non-required fields */
  box-shadow: none; /* Remove any box shadow */
  color: black;
}

.accordion-warning {
  .accordion-button {
    background-color: #FFA800;
  }

  .text-mof-primary {
    color: white;
  }
}

.field-warning {
  background-color: rgba(255, 193, 7, 0.4) !important;
}

.field-new {
  background-color: rgba(99, 248, 0, 0.4) !important;
}

.field-remove {
  background-color: rgba(248, 0, 0, 0.4) !important;
}

.navbar-size {
  height: 70px;
}

.navbar-logo {
  width: 137px;
}

.navbar-background {
  position: absolute;
  top: -54%;
  left: 2%;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 70%);

  .navbar-logo {
    left: 15%;
    top: 28%;
    position: absolute;
  }
}

.nav-blackground1 {
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(52,129,61,1) 50%, rgba(5,99,16,1) 100%);
}

@media only screen and (max-width: 768px) {
  .nav-blackground1 {
    background: rgb(255,255,255);
    background: rgba(5,99,16,1);
  }
}

.navbar-toggle {
  border-color: white;
}

.small-box {
  height: 20px;
  width: 20px;
}

.home-card {
  width: 220px;
  height: 180px;
}

.home-process-bar {
  height: 4px;
  margin: auto;
}