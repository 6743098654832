
body {
  background-color: #F5F5F5;
}

.login-page {
  max-width: 600px;
  min-width: 350px;
}

.login-logo {
  max-width: 450px;
}

.register-page {
  max-width: 800px;
  min-width: 350px;
}

.reset-password-page {
  max-width: 550px;
  min-width: 350px;
}